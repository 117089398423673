export const environment = {
    localDev: false,
    production: false,

    cognito: {
        UserPoolId: 'eu-central-1_qP9X5NJAu',
        ClientId: '5a41davebn7p6n5vn7qo45cf7j'
    },
    // region (mostly used for graphQL)
    awsRegion: 'eu-central-1',
    // timeout for hiding error messages etc.
    autoHideMessagesAfter: 3000,
    // this is DEV - URL for query, mutations
    graphQlUrl: 'https://graphql.api.portal-dev.wsim.cloud/graphql',
    // this is DEV - it's the URL for websocket (and therefore subscriptions!)
    graphQlRealtimeUrl: 'wss://graphql.api.portal-dev.wsim.cloud/graphql',

    aggrid: {
        rowBuffer: 100, // sets the number of rows the grid renders outside the viewable area - default: 10
        cacheBlockSize: 100, // How many rows for each block in the cache, i.e. how many rows returned from the server at a time.
        cacheOverflowSize: 2, // How many extra blank rows to display to the user at the end of the dataset, which sets the vertical scroll and then allows the grid to request viewing more rows of data. default: 1
        infiniteInitialRowCount: 1, // How many extra blank rows to display to the user at the end of the dataset, which sets the vertical scroll and then allows the grid to request viewing more rows of data.. default 1
        maxBlocksInCache: 100 // How many blocks to keep in the store. Default is no limit, so every requested block is kept. Use this if you have memory concerns, and blocks that were least recently viewed will be purged when the limit is hit. The grid will additionally make sure it has all the blocks needed to display what is currently visible, in case this property is set to a low value.
    },

    passwordRequirements: {
        minLength: 8,
        maxLength: 256
    },

    supportedLanguages: ['en', 'de']
};
