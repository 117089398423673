import _typeof from '@babel/runtime/helpers/esm/typeof';
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this;
    var args = arguments;
    var later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
function isWindow(obj) {
  return obj != null && obj === obj.window;
}
function getWindow(elem) {
  return isWindow(elem) ? elem : elem.nodeType === 9 && elem.defaultView;
}
function offset(elem) {
  var box = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  };
  var doc = elem && elem.ownerDocument;
  var docElem = doc && doc.documentElement;
  if (!docElem) return box;
  if (_typeof(elem.getBoundingClientRect) !== "undefined") {
    box = elem.getBoundingClientRect();
  }
  var win = getWindow(doc);
  var top = box.top + win.pageYOffset - docElem.clientTop;
  var left = box.left + win.pageXOffset - docElem.clientLeft;
  return {
    top: top,
    left: left,
    right: left + (box.right - box.left),
    bottom: top + (box.bottom - box.top)
  };
}
function getClickedElement(e) {
  if (e.srcElement && e.srcElement.nodeType === 1 && (e.srcElement.nodeName === 'BUTTON' || e.srcElement.nodeName === 'INPUT')) {
    if (e.srcElement.getAttribute && e.srcElement.getAttribute('ignorelocizeeditor') === '') {
      return null;
    }
    return e.srcElement;
  }
  var el;
  if (e.originalEvent && e.originalEvent.explicitOriginalTarget) {
    el = e.originalEvent.explicitOriginalTarget;
  } else {
    var parent = e.srcElement;
    if (parent.getAttribute && parent.getAttribute('ignorelocizeeditor') === '') return null;
    var left = e.pageX;
    var top = e.pageY;
    var topStartsAt = 0;
    var topBreaksAt;
    for (var i = 0; i < parent.childNodes.length; i++) {
      var n = parent.childNodes[i];
      var nOffset = offset(n);
      if (n.nodeType === 1 && nOffset.bottom < top) topStartsAt = i + 1;
      if (!topBreaksAt && nOffset.top + (n.clientHeight || 0) > top) topBreaksAt = i;
    }
    if (topStartsAt + 1 > parent.childNodes.length) topStartsAt = parent.childNodes.length - 1;
    if (!topBreaksAt) topBreaksAt = parent.childNodes.length;
    for (var y = topStartsAt; y < topBreaksAt; y++) {
      var _n = parent.childNodes[y];
      var _nOffset = offset(_n);
      if (_nOffset.left > left) {
        break;
      }
      if (_n && _n.nodeType !== 8) el = _n;
    }
  }
  return el;
}
function getElementText(el) {
  var str = el.textContent || el.text && el.text.innerText || el.placeholder;
  if (typeof str !== 'string') return;
  return str.replace(/\n +/g, '').trim();
}
function getAttribute(el, name) {
  return el && el.getAttribute && el.getAttribute(name);
}
function getElementI18nKey(el) {
  var key = getAttribute(el, 'data-i18n');
  if (key) return key;
  if (el.nodeType === window.Node.TEXT_NODE && el.parentElement) {
    return getElementI18nKey(el.parentElement);
  }
  return undefined;
}
function getElementNamespace(el) {
  var found;
  var find = function find(ele) {
    var opts = getAttribute(ele, 'i18next-options');
    if (!opts) opts = getAttribute(ele, 'data-i18next-options');
    if (!opts) opts = getAttribute(ele, 'i18n-options');
    if (!opts) opts = getAttribute(ele, 'data-i18n-options');
    if (opts) {
      var jsonData = {};
      try {
        jsonData = JSON.parse(opts);
      } catch (e) {}
      if (jsonData.ns) found = jsonData.ns;
    }
    if (!found) found = getAttribute(ele, 'i18next-ns');
    if (!found) found = getAttribute(ele, 'data-i18next-ns');
    if (!found) found = getAttribute(ele, 'i18n-ns');
    if (!found) found = getAttribute(ele, 'data-i18n-ns');
    if (!found && ele.parentElement) find(ele.parentElement);
  };
  find(el);
  return found;
}
function getQsParameterByName(name, url) {
  if (typeof window === 'undefined') return null;
  if (!url) url = window.location.href.toLowerCase();
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  var results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
export { debounce, getClickedElement, getElementI18nKey, getElementNamespace, getElementText, getQsParameterByName, getWindow, isWindow, offset };