import { startLegacy } from './processLegacy.js';
function startStandalone() {
  startLegacy({
    getLocizeDetails: function getLocizeDetails() {
      return {};
    },
    getLng: function getLng() {
      return undefined;
    },
    setResource: function setResource() {},
    triggerRerender: function triggerRerender() {},
    getResourceBundle: function getResourceBundle() {
      return {};
    },
    bindMissingKeyHandler: function bindMissingKeyHandler() {},
    bindLanguageChange: function bindLanguageChange() {}
  });
}
if (typeof window !== 'undefined') window.locizeStartStandalone = startStandalone;
export { startStandalone };