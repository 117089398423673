import { CellClickedEvent, ColDef, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import { I18NextPipe } from 'angular-i18next';
import { ByteFormatPipe } from '../pipes/byte-format.pipe';
import { MsisdnFormatPipe } from '../pipes/msisdn-format.pipe';
import { Permission } from '../service/auth/WSimAuth';
import { CustomFieldComponent } from '../shared/ag-cell-editor/custom-field/custom-field.component';
import { DataVolumeEditComponent } from '../shared/ag-cell-editor/data-volume-edit/data-volume-edit.component';
import { SendSmsComponent } from '../shared/ag-cell-editor/send-sms/send-sms.component';
import { SmsLimitEditComponent } from '../shared/ag-cell-editor/sms-limit-edit/sms-limit-edit.component';
import { DataVolumeLimitComponent } from '../shared/ag-cell-renderer/data-volume-limit/data-volume-limit.component';
import { InSessionComponent } from '../shared/ag-cell-renderer/in-session/in-session.component';
import { MsisdnComponent } from '../shared/ag-cell-renderer/msisdn/msisdn.component';
import { SimStatusComponent } from '../shared/ag-cell-renderer/sim-status/sim-status.component';
import { SmsAmountComponent } from '../shared/ag-cell-renderer/sms-amount/sms-amount.component';
import { SmsLimitComponent } from '../shared/ag-cell-renderer/sms-limit/sms-limit.component';
import { TextContainerWithCopyToClipboardComponent } from '../shared/ag-cell-renderer/text-container-with-copy-to-clipboard/text-container-with-copy-to-clipboard.component';
import { StatusFilterComponent } from '../shared/ag-filter/status-filter/status-filter.component';
import { CheckboxClearSelectorComponent } from '../shared/ag-headers/checkbox-clear-selector/checkbox-clear-selector.component';
import { StatusConstants } from '../shared/constants';

const byteFormatPipe: ByteFormatPipe = new ByteFormatPipe();

export function getCheckBoxDefinition(): ColDef {
    return {
        showDisabledCheckboxes: true,
        checkboxSelection: true,
        lockPosition: 'left',
        width: 30,
        maxWidth: 30,
        resizable: false,
        pinned: true,
        headerComponent: CheckboxClearSelectorComponent,
        suppressMovable: true,
        lockVisible: true
    };
}

export function getIccidDefinition(func: (event: CellClickedEvent) => void): ColDef {
    return {
        field: 'iccid',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['startsWith', 'equals', 'in'],
            maxNumConditions: 1,
            buttons: ['apply', 'clear']
        },
        sortable: true,
        minWidth: 180,
        cellRenderer: TextContainerWithCopyToClipboardComponent,
        lockPosition: true,
        pinned: true,
        suppressMovable: true,
        lockVisible: true,
        onCellClicked: func,
        cellClass: 'text-decoration-underline'
    };
}

export function getCustomfield1Definition(permissions: Set<string>, func: (event: CellClickedEvent) => void): ColDef {
    return {
        field: 'custom_field_1',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['startsWith', 'equals', 'in'],
            maxNumConditions: 1,
            buttons: ['apply', 'clear']
        },
        editable: permissions!.has(Permission.SIM_WRITE_CUSTOMFIELD1),
        cellRenderer: TextContainerWithCopyToClipboardComponent,
        cellEditor: CustomFieldComponent,
        minWidth: 250,
        enableCellChangeFlash: true,
        sortable: true,
        valueSetter: (params: ValueSetterParams) => {
            const rowNode = params.api.getRowNode(params.data.iccid);
            const dataCopy = JSON.parse(JSON.stringify(params.data));
            dataCopy.custom_field_1 = params.newValue;
            if (rowNode) {
                rowNode.updateData(dataCopy);
            }
            return true;
        },
        onCellClicked: func,
        cellClass: 'text-decoration-underline'
    };
}

export function getStatusIdDefinition(): ColDef {
    return {
        field: 'statusid',
        sortable: true,
        filter: StatusFilterComponent,
        cellRenderer: SimStatusComponent,
        enableCellChangeFlash: true,
        minWidth: 133
    };
}

export function getInSessionSinceDefinition(): ColDef {
    return {
        field: 'in_session_since',
        sortable: true,
        cellRenderer: InSessionComponent,
        maxWidth: 100,
        enableCellChangeFlash: true
    };
}

export function getPreviousMonthDataVolumeDefinition(): ColDef {
    return {
        field: 'previous_month_data_volume',
        sortable: true,
        type: 'rightAligned',
        minWidth: 140,
        maxWidth: 250,
        valueFormatter: (params: ValueFormatterParams) => {
            if (params.value) {
                const formatted = byteFormatPipe.transform(Number(params.value));
                return (formatted) ? String(formatted) : '';
            } else {
                return '';
            }
        },
        // For breaking the header dynamic to the header size
        wrapHeaderText: true
    };
}

export function getMonthlyDataDefinition(): ColDef {
    return {
        field: 'monthly_data_volume',
        sortable: true,
        type: 'rightAligned',
        maxWidth: 150,
        enableCellChangeFlash: true,
        valueFormatter: (params: ValueFormatterParams) => {
            if (params.value) {
                const formatted = byteFormatPipe.transform(Number(params.value));
                return (formatted) ? String(formatted) : '';
            } else {
                return '';
            }
        }
    };
}

export function getMonthlyDataLimitDefinition(permissions: Set<string>): ColDef {
    return {
        field: 'monthly_data_limit',
        sortable: true,
        cellRenderer: DataVolumeLimitComponent,
        cellEditor: DataVolumeEditComponent,
        type: 'rightAligned',
        minWidth: 150,
        maxWidth: 200,
        editable: permissions.has(Permission.SIM_WRITE_DATALIMIT),
        enableCellChangeFlash: true,
        // we need a value setter for a cell editor as when the value changes the grid tries to update
        // the immutable table data. so we need to create a copy of that and then re-set it and we have
        // as well the possibility to use our own object in the cell editor which is given back by
        // the method "getValue()" in the cell editor component
        valueSetter: (params: ValueSetterParams) => {
            if (params.newValue) {
                const rowNode = params.api.getRowNode(params.data.iccid);
                const dataCopy = JSON.parse(JSON.stringify(params.data));
                dataCopy.monthly_data_limit = params.newValue;
                if (rowNode) {
                    rowNode.updateData(dataCopy);
                }
                return true;
            }
            return false;
        }
    };
}

export function getMonthlyVoiceUsageDefinition(): ColDef {
    return {
        field: 'monthly_voice_mo',
        minWidth: 120,
        maxWidth: 200,
        type: 'rightAligned',
        editable: false,
        sortable: true,
        valueGetter: (value) => {
            const duration = value.data?.monthly_voice_mo;
            if (!duration) return;
            const hours = Math.floor(duration / 3600);
            const minutes = Math.floor((duration % 3600) / 60);
            const seconds = duration % 60;
            return [hours, minutes, seconds].map((number) => number.toString().padStart(2, '0')).join(':');
        }
    };
}


export function getMonthlySmsDefinition(permissions: Set<string>): ColDef {
    return {
        field: 'monthly_sms',
        minWidth: 180,
        maxWidth: 200,
        type: 'rightAligned',
        editable: permissions.has(Permission.SIM_EXECUTE_SENDSMS),
        cellRenderer: SmsAmountComponent,
        cellEditor: SendSmsComponent,
        cellEditorPopup: true,
        enableCellChangeFlash: true
    };
}

export function getMonthlySmsLimitDefinition(permissions: Set<string>): ColDef {
    return {
        field: 'monthly_sms_mt_limit',
        minWidth: 180,
        maxWidth: 200,
        type: 'rightAligned',
        editable: permissions.has(Permission.SIM_WRITE_SMSLIMIT),
        cellRenderer: SmsLimitComponent,
        cellEditor: SmsLimitEditComponent,
        enableCellChangeFlash: true,
        // see data limit editor
        valueSetter: (params: ValueSetterParams): boolean => {
            if (params.newValue) {
                const rowNode = params.api.getRowNode(params.data.iccid);
                const dataCopy = JSON.parse(JSON.stringify(params.data));
                dataCopy.monthly_sms_mo_limit = params.newValue.monthly_sms_mo_limit;
                dataCopy.monthly_sms_mt_limit = params.newValue.monthly_sms_mt_limit;
                if (rowNode) {
                    rowNode.updateData(dataCopy);
                }
                return true;
            }
            return false;
        },
        valueGetter: (params: ValueGetterParams) => {
            // the following is needed, otherwise the exclamation mark symbol will not update properly.
            // the cell renderer itself returns the value that is displayed, not this function here. this function
            // just signalizes that something has changed so the renderer gets an update!
            if (params.data) {
                if (params.data.statusid === StatusConstants.SMS_LIMIT_EXCEEDED) {
                    return params.data.monthly_sms_mo_limit + '_';
                }
                return params.data.monthly_sms_mo_limit;
            }
        }
    };
}

export function getProviderIdDefinition(i18nextPipe: I18NextPipe): ColDef {
    return {
        field: 'providerid',
        sortable: true,
        minWidth: 100,
        maxWidth: 100,
        valueFormatter: (params: ValueFormatterParams) => {
            if (params.value) {
                const formatted = i18nextPipe.transform('product.prod_' + params.value);
                return String(formatted);
            } else {
                return '';
            }
        }
    };
}

export function getImeiDefinition(): ColDef {
    return {
        field: 'imei',
        filter: 'agTextColumnFilter',
        minWidth: 180,
        maxWidth: 250,
        filterParams: {
            filterOptions: ['startsWith', 'equals', 'in'],
            maxNumConditions: 1,
            buttons: ['apply', 'clear']
        },
        sortable: true,
        enableCellChangeFlash: true,
        cellRenderer: TextContainerWithCopyToClipboardComponent,
        valueFormatter: (params: ValueFormatterParams) => (params?.value || '')
    };
}

export function getImsiDefinition(): ColDef {
    return {
        field: 'imsi',
        filter: 'agTextColumnFilter',
        minWidth: 188,
        maxWidth: 250,
        filterParams: {
            filterOptions: ['startsWith', 'equals', 'in'],
            maxNumConditions: 1,
            buttons: ['apply', 'clear']
        },
        sortable: true,
        cellRenderer: TextContainerWithCopyToClipboardComponent,
        valueFormatter: (params: ValueFormatterParams) => (params?.value || ''),
        hide: true
    };
}

export function getMsisdnDefinition(msisdnFormatPipe: MsisdnFormatPipe): ColDef {
    return {
        field: 'msisdn',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['startsWith', 'equals', 'in'],
            maxNumConditions: 1,
            buttons: ['apply', 'clear']
        },
        minWidth: 185,
        maxWidth: 230,
        sortable: true,
        cellRenderer: MsisdnComponent,
        valueFormatter: (params: ValueFormatterParams) => (msisdnFormatPipe.transform(params.value) || '')
    };
}

export function getActivationTimestampDefinition(i18nextPipe: I18NextPipe): ColDef {
    return {
        field: 'activation_timestamp',
        sortable: true,
        cellDataType: 'date',
        minWidth: 155,
        enableCellChangeFlash: true,
        valueFormatter: (params: ValueFormatterParams) => {
            if (params.value) {
                const formatted = i18nextPipe.transform('formats:dateShort', { date: params.value });
                return String(formatted);
            } else {
                return '';
            }
        }
    };
}
