<div class="d-flex flex-row justify-content-between align-items-center my-3">
  <span class="text-secondary fs-2">
    {{ 'user-management.title'|i18nextCap }}
  </span>
  <button *hasPermission="['customer.write.accounts']"
    class="btn btn-secondary btn-customized" data-cy="open-create-user-dialog-button"
    type="button"
    (click)="openModalCreateUser()"
    [disabled]="!hasCreateUserPermission()">
    <i class="fa-solid fa-plus"></i>
    {{ 'user-management.createUser.label' | i18next }}
  </button>
</div>
<div>
  <dx-data-grid
    #userGrid
    id="userManagement"
    [dataSource]="dataSource"
    [showBorders]="true"
    [showRowLines]="true"
    [allowColumnResizing]="true"
    [wordWrapEnabled]="true"
    [remoteOperations]="{ paging: false }"
    [rowAlternationEnabled]="true"
    [repaintChangesOnly]="true"
    (onOptionChanged)="onOptionChanged($event)"
    class="set-height"
  >
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>

    <dxi-column dataField="email" dataType="string"
                [caption]="this.i18next.transform('user-management.table.header.email', { format: 'Cap'})"
                [allowHeaderFiltering]="false"
                headerCellTemplate="emailHeaderCellTemplate"
                [minWidth]="400"></dxi-column>

    <div *dxTemplate="let data of 'emailHeaderCellTemplate'">
      <span class="fw-bold">
      {{ 'user-management.table.header.email'|i18nextCap }}
      </span>
    </div>

    <dxi-column dataField="groups"
                [allowSorting]="false"
                [allowHeaderFiltering]="false"
                [caption]="this.i18next.transform('user-management.table.header.groups', { format: 'Cap'})"
                headerCellTemplate="groupsHeaderCellTemplate"
                cellTemplate="cellTemplateGroups"
                [width]="400"></dxi-column>

    <div *dxTemplate="let data of 'groupsHeaderCellTemplate'">
      <span class="fw-bold">
      {{ 'user-management.table.header.groups'|i18nextCap }}
      </span>
      <span>
          <i [id]="'exclamationMark-user-management'" class="fa-regular fa-circle-question">
          <app-default-popover
            [text]="i18next.transform('roles.popover.description')"
            [componentId]="'exclamationMark-user-management'">
          </app-default-popover>
        </i>
      </span>
    </div>


    <div *dxTemplate="let data of 'cellTemplateGroups'">
      <div class="d-flex flex-row">
        <span *ngIf="data.data.groups?.[0]==='Administrator'" class="text-truncate flex-fill mb-auto">
          {{ 'user-management.table.groups.administrator'|i18nextCap }}
        </span>
        <span *ngIf="data.data.groups?.[0]==='Observer'" class="text-truncate flex-fill mb-auto">
          {{ 'user-management.table.groups.observer'|i18nextCap }}
        </span>
        <span *ngIf="data.data.groups?.[0]==='Manager'" class="text-truncate flex-fill mb-auto">
          {{ 'user-management.table.groups.manager'|i18nextCap }}
        </span>
        <span *ngIf="data.data.groups?.[0]==='WSIM-Super-Admin'" class="text-truncate flex-fill mb-auto">
          {{ 'user-management.table.groups.wsim-super-admin'|i18nextCap }}
        </span>
        <div *hasPermission="['customer.write.accounts']" class="ps-1 pe-1">
          <i *ngIf="!isSameUser(data.data.email)" class="fa-solid fa-pen p btn p-0"
             [attr.data-cy]="'update-user-' + data.data.id"
             (click)="openModalUpdateUser(data.data)"></i>
        </div>
      </div>
    </div>

    <dxi-column dataField="status" [allowSorting]="false"
                [caption]="this.i18next.transform('user-management.table.header.status', { format: 'Cap'})"
                [allowFiltering]="false"
                [width]="400"
                headerCellTemplate="statusHeaderCellTemplate"
                cellTemplate="cellTemplateStatus"></dxi-column>

    <div *dxTemplate="let data of 'statusHeaderCellTemplate'">
      <span class="fw-bold">
        {{ 'user-management.table.header.status'|i18nextCap }}
      </span>
    </div>

    <div *dxTemplate="let data of 'cellTemplateStatus'">

      <div *ngIf="data.data.status==='HasAccess'" class="d-flex flex-row">
        <span class="pe-1">
          <i class="fa-solid fa-circle-check"></i>
        </span>
        <span class="text-truncate flex-fill mb-auto">
          {{ 'user-management.table.status.HasAccess'|i18nextCap }}
        </span>
      </div>

      <div *ngIf="data.data.status==='InvitationSent'" class="d-flex flex-row">
        <span class="pe-1">
          <i class="fa-solid fa-hourglass-half"></i>
        </span>
        <span class="text-truncate flex-fill mb-auto">
          {{ 'user-management.table.status.InvitationSent'|i18nextCap }}
        </span>
        <span *hasPermission="['customer.write.accounts','customer.read.accounts']">
          <i class="fa-solid fa-rotate-right  p btn p-0" (click)="openModalResendUserInvite(data.data)"
             [attr.data-cy]="'resend-invite-' + data.data.id"></i>
        </span>
      </div>

      <div *ngIf="data.data.status==='InvitationExpired'" class="d-flex flex-row">
        <span class="pe-1">
          <i class="fa-solid fa-circle-xmark"></i>
        </span>
        <span class="text-truncate flex-fill mb-auto">
           {{ 'user-management.table.status.InvitationExpired'|i18nextCap }}
        </span>
        <span *hasPermission="['customer.write.accounts','customer.read.accounts']">
            <i class="fa-solid fa-rotate-right  p btn p-0" (click)="openModalResendUserInvite(data.data)"
               [attr.data-cy]="'resend-invite-' + data.data.id"></i>
        </span>
      </div>

      <div *ngIf="data.data.status==='Disabled'" class="d-flex flex-row">
        <span class="pe-1">
           <i class="fa-solid fa-circle-pause"></i>
        </span>
        <span class="text-truncate flex-fill mb-auto">
           {{ 'user-management.table.status.Disabled'|i18nextCap }}
        </span>
      </div>

    </div>

    <dxi-column *hasPermission="['customer.write.accounts','customer.read.accounts']" dataField=""
                [allowSorting]="false"
                [caption]=""
                [allowFiltering]="false"
                [width]="40"
                cellTemplate="cellTemplateDelete"></dxi-column>

    <div *dxTemplate="let data of 'cellTemplateDelete'">
      <div class="d-flex flex-row justify-content-center">
        <div *hasPermission="['customer.write.accounts','customer.read.accounts']">
          <i *ngIf="!isSameUser(data.data.email)" class="fa-solid fa-trash-can p btn p-0"
             [attr.data-cy]="'delete-user-' + data.data.id"
             (click)="openModalDeleteUser(data.data)"></i>
        </div>
      </div>
    </div>
  </dx-data-grid>
</div>

<app-modal-update-user *hasPermission="['customer.write.accounts']" #modalUpdateUser
                       (userUpdated)="reloadUsers()" data-target="userGrid">
</app-modal-update-user>
<app-modal-create-user (userCreated)="reloadUsers()" #modalCreateUser></app-modal-create-user>
<app-modal-resend-user-invite (resentInvite)="reloadUsers()" #modalResendUserInvite></app-modal-resend-user-invite>
<app-modal-delete-user (userDeleted)="reloadUsers()" #modalDeleteUser></app-modal-delete-user>
