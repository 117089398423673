import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';
import { Auth } from './WSimAuth';

function checkPermissions(permissionsList: Array<string>): boolean {
    let permissionsMismatches: number = 0;
    for (const requiredPermission of permissionsList) {
        if (!Auth.getcurrentUserPermissionsList().has(requiredPermission)) {
            permissionsMismatches++;
        }
    }
    return permissionsMismatches === 0;
}

export const permissionGuard: CanActivateFn = (route) => {
    const authService = inject(AuthService);
    if (!authService.isLoggedIn())
        return false;
    const requiredPermissions: Array<string> = route.data['permission'];
    return checkPermissions(requiredPermissions);
};
