import _defineProperty from '@babel/runtime/helpers/esm/defineProperty';
import { getIframeUrl } from '../vars.js';
import { store } from '../store.js';
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
var legacyEventMapping = {
  committed: 'commitKeys'
};
function getMappedLegacyEvent(msg) {
  if (legacyEventMapping[msg]) return legacyEventMapping[msg];
  return msg;
}
function addLocizeSavedHandler(handler) {
  api.locizeSavedHandler = handler;
}
function turnOn() {
  api.scriptTurnedOff = false;
  api.turnOn();
  return api.scriptTurnedOff;
}
function turnOff() {
  api.turnOff();
  api.scriptTurnedOff = true;
  return api.scriptTurnedOff;
}
function setEditorLng(lng) {
  api.sendCurrentTargetLanguage(lng);
}
var pendingMsgs = [];
function sendMessage(action, payload) {
  if (!api.source) {
    var _document$getElementB;
    api.source = (_document$getElementB = document.getElementById('i18next-editor-iframe')) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.contentWindow;
  }
  if (!api.origin) api.origin = getIframeUrl();
  if (!api.source || !api.source.postMessage) {
    pendingMsgs.push({
      action: action,
      payload: payload
    });
    return;
  }
  if (api.legacy) {
    api.source.postMessage(_objectSpread({
      message: action
    }, payload), api.origin);
  } else {
    api.source.postMessage({
      sender: 'i18next-editor',
      senderAPIVersion: 'v1',
      action: action,
      message: action,
      payload: payload
    }, api.origin);
  }
  var todo = pendingMsgs;
  pendingMsgs = [];
  todo.forEach(function (_ref) {
    var action = _ref.action,
      payload = _ref.payload;
    sendMessage(action, payload);
  });
}
var handlers = {};
var repeat = 5;
var api = {
  init: function init(implementation, clickHandler) {
    api.i18n = implementation;
    api.clickHandler = clickHandler;
  },
  requestInitialize: function requestInitialize(payload) {
    sendMessage('requestInitialize', payload);
    if (api.initInterval) return;
    api.initInterval = setInterval(function () {
      repeat = repeat - 1;
      api.requestInitialize(payload);
      if (repeat < 0 && api.initInterval) {
        clearInterval(api.initInterval);
        delete api.initInterval;
      }
    }, 1000);
  },
  selectKey: function selectKey(meta) {
    sendMessage('selectKey', meta);
  },
  confirmResourceBundle: function confirmResourceBundle(payload) {
    sendMessage('confirmResourceBundle', payload);
  },
  sendCurrentParsedContent: function sendCurrentParsedContent() {
    sendMessage('sendCurrentParsedContent', {
      content: Object.values(store.data).map(function (item) {
        return {
          id: item.id,
          keys: item.keys
        };
      })
    });
  },
  sendCurrentTargetLanguage: function sendCurrentTargetLanguage(lng) {
    sendMessage('sendCurrentTargetLanguage', {
      targetLng: lng || api.i18n.getLng()
    });
  },
  addHandler: function addHandler(action, fc) {
    if (!handlers[action]) handlers[action] = [];
    handlers[action].push(fc);
  },
  sendLocizeIsEnabled: function sendLocizeIsEnabled() {
    sendMessage('locizeIsEnabled', {
      enabled: true
    });
  },
  turnOn: function turnOn() {
    if (api.scriptTurnedOff) return sendMessage('forcedOff');
    if (!api.clickInterceptionEnabled) {
      window.document.body.addEventListener('click', api.clickHandler, true);
    }
    api.clickInterceptionEnabled = true;
    sendMessage('turnedOn');
  },
  turnOff: function turnOff() {
    if (api.scriptTurnedOff) return sendMessage('forcedOff');
    if (api.clickInterceptionEnabled) {
      window.document.body.removeEventListener('click', api.clickHandler, true);
    }
    api.clickInterceptionEnabled = false;
    sendMessage('turnedOff');
  },
  onAddedKey: function onAddedKey(lng, ns, key, value) {
    var msg = {
      lng: lng,
      ns: ns,
      key: key,
      value: value
    };
    sendMessage('added', msg);
  }
};
if (typeof window !== 'undefined') {
  window.addEventListener('message', function (e) {
    var _e$data = e.data,
      sender = _e$data.sender,
      action = _e$data.action,
      message = _e$data.message,
      payload = _e$data.payload;
    if (message) {
      var usedEventName = getMappedLegacyEvent(message);
      if (handlers[usedEventName]) {
        handlers[usedEventName].forEach(function (fc) {
          fc(payload, e);
        });
      }
    } else if (sender === 'i18next-editor-frame' && handlers[action]) {
      handlers[action].forEach(function (fc) {
        fc(payload);
      });
    }
  });
}
export { addLocizeSavedHandler, api, sendMessage, setEditorLng, turnOff, turnOn };