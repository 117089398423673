import _toConsumableArray from '@babel/runtime/helpers/esm/toConsumableArray';
import { api } from './postMessage.js';
import { store } from '../store.js';
import { setValueOnNode } from './handleEditKey.js';
function handler(payload) {
  var updated = payload.updated;
  updated.forEach(function (item) {
    var lng = item.lng,
      ns = item.ns,
      key = item.key,
      data = item.data,
      metas = item.metas,
      meta = item.meta;
    if (meta && data.value) setValueOnNode(meta, data.value);
    if (metas) {
      Object.values(metas).forEach(function (metaItem) {
        setValueOnNode(metaItem, data.value);
      });
    }
    api.i18n.setResource(lng, ns, key, data.value);
  });
  Object.values(store.data).forEach(function (item) {
    if (item.originalChildNodes) {
      var _item$node;
      (_item$node = item.node).replaceChildren.apply(_item$node, _toConsumableArray(item.originalChildNodes));
    }
  });
  api.i18n.triggerRerender();
  if (api.locizeSavedHandler) api.locizeSavedHandler(payload);
  if (window.locizeSavedHandler) window.locizeSavedHandler(payload);
}
api.addHandler('commitKeys', handler);