import { Component, EventEmitter, inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApolloError, FetchResult } from '@apollo/client/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { Maybe } from 'graphql/jsutils/Maybe';
import { UpdateUserAccountMutation, UserAccount } from '../../../../graphql/graphql.generated';
import { Auth } from '../../service/auth/WSimAuth';
import { GraphqlService } from '../../service/graphql.service';
import { LoggerService } from '../../service/logger';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';

@Component({
    selector: 'app-modal-update-user',
    templateUrl: './modal-update-user.component.html',
    styleUrl: './modal-update-user.component.scss'
})
export class ModalUpdateUserComponent implements OnInit {

    protected selectedData: UserAccount | undefined;
    protected roleList: Array<string> = new Array<string>(...Auth.getPermissionsKeys());
    protected roles: { label: string, value: string }[] = [];
    protected currentRole: Maybe<string>;
    protected i18nextPipe: I18NextPipe = inject(I18NextPipe);

    @Output() private userUpdated: EventEmitter<void> = new EventEmitter();
    @ViewChild('content') private content: TemplateRef<never> | undefined;

    private graphql: GraphqlService = inject(GraphqlService);
    private toastService: ToastService = inject(ToastService);
    private modalService: NgbModal = inject(NgbModal);
    private modal?: NgbModalRef;

    public formGroup: FormGroup = new FormGroup({
        role: new FormControl('Manager', { nonNullable: true, validators: [Validators.required] })
    });

    public open() {
        this.modal = this.modalService.open(this.content, { ariaLabelledBy: 'updateUserLabel' });
        return this.modal;
    }

    protected onClose() {
        this.formGroup.reset();
        this.modal?.close();
    }

    protected onSubmit(): void {
        if (!this.selectedData) return;
        const role: string = this.formGroup.value ? this.formGroup.value.role : this.selectedData.groups ? [0] : 'Manager';
        const { id, locale, zoneinfo } = this.selectedData;
        this.graphql.updatesUserAccount(locale, zoneinfo, id, [role])
            .then((userAccount: FetchResult<UpdateUserAccountMutation>): void => {
                LoggerService.debug('The selected user was updated to: ', userAccount.data);
                this.userUpdated.emit();
            })
            .catch((e: ApolloError): void => {
                this.graphqlErrorResolver(e);
            });
        this.onClose();
    }

    /**
     * For handling error coming from the graphql promises
     * @Param error The error catch from the promise
     * */
    private graphqlErrorResolver(error: ApolloError): void {
        const text: string = this.i18nextPipe.transform('user-management.updateUser.graphql-operations.error', { error: error.message });
        this.toastService.show(ToastMessageBuilder.error().text(text).build());
    }

    public setSelectedData(userAccount: UserAccount) {
        this.selectedData = userAccount;
        this.currentRole = this.selectedData?.groups ? this.selectedData.groups[0] : '';
        this.formGroup.setValue({ role: this.currentRole });
    }

    public ngOnInit(): void {
        if (!Auth.isAdmin()) this.roleList = this.roleList.filter(item => item !== 'WSIM-Super-Admin');
        this.roles = this.roleList.map((role) => ({
            label: this.i18nextPipe.transform('user-management.createUser.role-' + role), value: role
        }));
    }
}
