/**
 * The datasource for the ag-grid table. We need a datasource (only) when using infinite scrolling,
 * otherwise we can use normal data arrays.
 *
 * This datasource only has 1 public method - getRows which is called by ag-grid framework when
 * data is requested.
 *
 * The datasource also contains the requests to graphQL as well as the response methods for querying data.
 */
import { ApolloError } from '@apollo/client/core';
import { GridApi, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { ListSimsQuery } from '../../../graphql/graphql.generated';
import { GraphqlService } from '../service/graphql.service';
import { LoggerService } from '../service/logger';
import { ToastMessageBuilder, ToastService } from '../service/toast.service';

export class SimDatasource implements IDatasource {

    private _nextToken: string | undefined;
    private _pageSize = 100;
    private gridApi?: GridApi;
    private quickFilter: string | undefined;

    public constructor(private graphQlClient: GraphqlService, private toastService: ToastService) {
        LoggerService.debug('Creating new SimDatasource!');
    }

    // filter mapping from AG-Grid Filter to GraphQL filter keys
    private agFilterToGraphQlMapping: { [index: string]: string } = {
        'startsWith': 'beginsWith',
        'equals': 'eq',
        'in': 'in'
    };

    public setGridApi(gridApi?: GridApi) {
        this.gridApi = gridApi;
    }

    public setQuickFilter(filter: string | undefined) {
        this.quickFilter = filter;
    }

    public getRows(params: IGetRowsParams): void {
        LoggerService.debug('SimDatasource.getRows(): params are: ', params);
        // start row resets to zero when we start a new table model
        if (params.startRow == 0) {
            this._nextToken = undefined;
            this.gridApi?.setGridOption('loading', true);
        }

        this.graphQlClient.getSimList(this._pageSize,
            this._nextToken,
            this.getFilterFromRowParams(params),
            this.getSortFromRowParams(params),
            this.quickFilter)
            .then(({ data, error }) => this.onSimListDataReceived(data, error, params))
            .catch((error) => this.onSimListDataError(error, params));
    }

    private getFilterFromRowParams(params: IGetRowsParams): { [index: string]: object } | undefined {
        let filter: { [index: string]: object } | undefined;
        LoggerService.debug('SimDatasource.getFilterFromRowParams(): params are: ', params);

        // check the filter
        if (params.filterModel) {
            filter = {};
            // msisdn : {filterType: 'text', type: 'startsWith', filter: '123'}
            for (let filterKey in params.filterModel) {
                const specificFilter: { [index: string]: object } = {};
                const typeFromAgFilter = params.filterModel[filterKey]['type'];
                // we need another type for GraphQL ... startsWith -> beginsWith etc.
                const graphQlFilterType = this.agFilterToGraphQlMapping[typeFromAgFilter];
                let filterValue = params.filterModel[filterKey]['filter'];
                LoggerService.debug(`SimDatasource: graphQlFilterType: ${graphQlFilterType} with filter ${filterValue} and filterType ${typeof filterValue}`);
                if (graphQlFilterType === 'in' && typeof filterValue === 'string') {
                    // split filter value by comma!
                    filterValue = filterValue.split(',');
                }
                specificFilter[graphQlFilterType] = filterValue;


                // special ... we need to change that in backend
                if (filterKey === 'status') {
                    filterKey = 'statusid';
                }
                filter[filterKey] = specificFilter;
            }
        }

        LoggerService.debug('SimDatasource: Created filter: ', filter);
        return filter;
    }

    /**
     * This method get the sort from the params from the getRows request and creates a sort for the graphQL
     * @param params the params coming from getRows call
     * @return a filter for graphQL or undefined, if no filter is set!
     */
    private getSortFromRowParams(params: IGetRowsParams): object | undefined {
        const sort: { [index: string]: string } = {};
        // check the sorting
        if (params.sortModel && params.sortModel.length > 0) {
            // there is only 1 column that can be sorted currently
            const firstSort = params.sortModel[0];
            sort[firstSort.colId] = firstSort.sort;
        }
        LoggerService.debug('SimDatasource: Created Sort: ', sort);
        return sort;
    }

    /**
     * This method is called when we receive data from the graphQL call
     * @param data the data itself coming from the graphQL service
     * @param error if any error occurred
     * @param paramsCallback the callback for the result that has to be used (append data to table)
     * @private
     */
    private onSimListDataReceived(data: ListSimsQuery | undefined, error: Error | undefined,
                                  paramsCallback: IGetRowsParams) {
        LoggerService.debug('SimDatasource: onSimListDataReceived(): got valueChanges -> ', data, paramsCallback);
        if (!error) {
            this.gridApi?.setGridOption('loading', false);
            if (data && !data.listSims) {
                if (!this._nextToken) {
                    LoggerService.debug('SimDatasource: onSimListDataReceived(): received empty list. No data!');
                    // this was an initial request
                    paramsCallback.successCallback([], 0);
                }
                this._nextToken = undefined;
            } else if (data && data.listSims?.nextToken && data.listSims?.nextToken !== '') {
                LoggerService.debug('SimDatasource: onSimListDataReceived(): setting next token: ', data.listSims.nextToken);
                this._nextToken = data.listSims.nextToken;
            } else {
                this._nextToken = undefined;
            }
            if (data && data.listSims?.items && paramsCallback) {
                if (this._nextToken) {
                    LoggerService.debug(`SimDatasource: We have a next token. So not reached the end of the list!`);
                    paramsCallback.successCallback(data.listSims.items);
                } else {
                    const lastRow = paramsCallback.startRow + data.listSims.items.length;
                    LoggerService.debug(`SimDatasource: We have no next token. Sending callback row data with lastRow: ${lastRow}`);
                    paramsCallback.successCallback(data.listSims.items, lastRow);
                }
            } else if (data && !data.listSims) {
                LoggerService.debug(`SimDatasource: We got data but no SIM list. Sending callback with empty list, lastRow: ${paramsCallback.startRow}`);
                paramsCallback.successCallback([], paramsCallback.startRow);
            }
        } else {
            this.toastService.show(ToastMessageBuilder.error().text('Backend Error: ' + error?.message).build());
        }
    }

    private onSimListDataError(error: ApolloError | undefined, params: IGetRowsParams) {
        if (error) {
            this.toastService.show(ToastMessageBuilder.error().text('Backend Error: ' + error?.message).build());
            params.failCallback();
        }
    }

}
