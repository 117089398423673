<ng-template #content let-modal>
  <div class="modal-header" data-cy="modal-bulk-change-sim-custom-field1-dialog">
    <h1 class="modal-title fs-5"
        id="bulkChangeSimCustomField1Label">{{ 'bulkCustomField1Change.title' | i18next }}</h1>
    <button type="button" class="btn-close" aria-label="Close" [disabled]="inProgress" (click)="modal.close('X')">
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          {{ 'bulkCustomField1Change.changeCustomField1OfXSims' | i18next: {count: selectedSimCards?.length} }}
        </div>
      </div>
      <div class="row pt-2">
        <div class="col">
          <input class="form-control" autofocus id="customField1" (input)="onChangeCustomField1()" name="customField1"
                 maxlength="255" type="text" [placeholder]="'table.header.custom_field_1' | i18next"
                 [(ngModel)]="customField1Value" (keyup.enter)="onKeyUpEnter()"
                 data-cy="modal-bulk-change-sim-custom-field1-input"/>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="col float-start">
      <button type="button" class="btn btn-secondary" [disabled]="inProgress" (click)="modal.close('Cancel')"
              data-cy="modal-bulk-change-sim-custom-field1-cancel-button">
        {{ 'shared.btn.cancel' | i18next }}
      </button>
    </div>
    <div class="col float-end text-end">
      <button type="button" (click)="commitChange()" [disabled]="!saveEnabled || inProgress"
              class="btn btn-primary" data-cy="modal-bulk-change-sim-custom-field1-save-button">
        {{ 'apply' | i18next }}
        <span *ngIf="inProgress" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</ng-template>
