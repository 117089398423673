import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { I18NextPipe } from 'angular-i18next';
import { DxDataGridComponent } from 'devextreme-angular';
import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { ListUserAccountsQuery, UserAccount } from '../../../../graphql/graphql.generated';
import { ModalResendUserInviteComponent } from '../../dialogs/modal-resend-user-invite/modal-resend-user-invite.component';
import { ModalUpdateUserComponent } from '../../dialogs/modal-update-user/modal-update-user.component';
import { ModalDeleteUserComponent } from '../../dialogs/modal-delete-user/modal-delete-user.component';
import { Auth, Permission } from '../../service/auth/WSimAuth';
import { GraphqlService } from '../../service/graphql.service';
import { LoggerService } from '../../service/logger';
import { StatusConstants } from '../../shared/constants';
import { ModalCreateUserComponent } from '../../dialogs/modal-create-user/modal-create-user.component';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrl: './user-management.component.scss'
})
export class UserManagementComponent implements OnDestroy {

    @ViewChild('modalCreateUser') private modalCreateUser?: ModalCreateUserComponent;
    @ViewChild('modalUpdateUser') private modalUpdateUser?: ModalUpdateUserComponent;
    @ViewChild('modalResendUserInvite') private modalResendUserInvite?: ModalResendUserInviteComponent;
    @ViewChild('modalDeleteUser') private modalDeleteUser?: ModalDeleteUserComponent;
    @ViewChild('dataGrid', { static: false }) protected userGrid: DxDataGridComponent | undefined;

    private graphql: GraphqlService = inject(GraphqlService);
    private permissions;

    private readonly store: CustomStore;

    protected i18next: I18NextPipe = inject(I18NextPipe);
    protected dataSource: DataSource;

    protected readonly StatusConstants = StatusConstants;
    protected readonly Auth = Auth;
    protected readonly Object = Object;

    public constructor() {
        this.permissions = Auth.getcurrentUserPermissionsList();
        this.store = new CustomStore({
            key: 'id',
            load: async (loadOptions: LoadOptions) => {
                LoggerService.debug('CustomStore load', loadOptions);
                return await this.graphql.listUserAccounts().then((response: ApolloQueryResult<ListUserAccountsQuery>) => {
                    LoggerService.debug('Got account list: ', response.data.listUserAccounts);
                    return {
                        data: response.data.listUserAccounts,
                        totalCount: response.data.listUserAccounts.length
                    };
                });

            }
        });

        this.dataSource = new DataSource({
            store: this.store,
            reshapeOnPush: true,
            paginate: false,
            map: (item) => item
        });
    }

    protected reloadUsers() {
        this.dataSource.reload();
    }

    protected isSameUser(email: string): boolean {
        return email === Auth.getCurrentEmail();
    }

    public openModalCreateUser(): void {
        this.modalCreateUser?.open();
    }

    public openModalUpdateUser(data: UserAccount): void {
        this.modalUpdateUser?.setSelectedData(data);
        this.modalUpdateUser?.open();
    }

    public openModalResendUserInvite(userAccount: UserAccount): void {
        this.modalResendUserInvite?.setSelectedUserAccount(userAccount);
        this.modalResendUserInvite?.open();
    }

    public openModalDeleteUser(userAccount: UserAccount): void {
        this.modalDeleteUser?.setUserAccount(userAccount);
        this.modalDeleteUser?.open();
    }

    protected hasCreateUserPermission() {
        return this.permissions.has(Permission.CUSTOMER_WRITE_ACCOUNTS);
    }

    // @ts-expect-error {fullname:string, name: string, component:object, value:object, previousValue:object}
    public onOptionChanged(e): void {
        if (e.fullName.includes('sortOrder')) {
            const startIdx: number = e.fullName.indexOf('[') + 1;
            const endIdx: number = e.fullName.indexOf(']');
            const idx: number = parseInt(e.fullName.slice(startIdx, endIdx));
            if (e.previousValue === 'desc') {
                e.component.columnOption(idx, 'sortOrder', undefined);
            }
        }
    }

    public ngOnDestroy(): void {
        this.dataSource.dispose();
    }
}
