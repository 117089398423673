import { api } from './postMessage.js';
import { popupId } from '../ui/elements/popup.js';
function handler(payload) {
  var containerStyle = payload.containerStyle;
  if (containerStyle) {
    var popup = document.getElementById(popupId);
    if (containerStyle.height) {
      var diff = "calc(".concat(containerStyle.height, " - ").concat(popup.style.height, ")");
      popup.style.setProperty('top', "calc(".concat(popup.style.top, " - ").concat(diff, ")"));
      popup.style.setProperty('height', containerStyle.height);
    }
    if (containerStyle.width) {
      var _diff = "calc(".concat(containerStyle.width, " - ").concat(popup.style.width, ")");
      popup.style.setProperty('left', "calc(".concat(popup.style.left, " - ").concat(_diff, ")"));
      popup.style.setProperty('width', containerStyle.width);
    }
  }
}
api.addHandler('requestPopupChanges', handler);