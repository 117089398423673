import { containsHiddenMeta, unwrap } from 'i18next-subliminal';
import { getClickedElement, getElementText, getElementI18nKey, getElementNamespace } from './utils.js';
function createClickHandler(cb) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var handler = function handler(e) {
    var el = getClickedElement(e);
    if (!el) return {};
    e.preventDefault();
    e.stopPropagation();
    function getFallbackNS() {
      if (options.isLocizify) return options.defaultNS;
    }
    var text = getElementText(el);
    var key = getElementI18nKey(el);
    var ns = getElementNamespace(el) || getFallbackNS();
    if (containsHiddenMeta(text)) {
      var meta = unwrap(text);
      if (meta && meta.invisibleMeta && meta.invisibleMeta.key) key = meta.invisibleMeta.key;
      if (meta && meta.invisibleMeta && meta.invisibleMeta.ns) ns = meta.invisibleMeta.ns;
    }
    var rectEl = el.getBoundingClientRect ? el : el.parentElement;
    var _rectEl$getBoundingCl = rectEl.getBoundingClientRect(),
      top = _rectEl$getBoundingCl.top,
      left = _rectEl$getBoundingCl.left,
      width = _rectEl$getBoundingCl.width,
      height = _rectEl$getBoundingCl.height;
    var style = window.getComputedStyle(rectEl, null);
    var pT = parseFloat(style.getPropertyValue('padding-top'));
    var pB = parseFloat(style.getPropertyValue('padding-bottom'));
    var pR = parseFloat(style.getPropertyValue('padding-right'));
    var pL = parseFloat(style.getPropertyValue('padding-left'));
    var sizing = style.getPropertyValue('box-sizing');
    cb({
      tagName: rectEl.tagName,
      text: text,
      key: key,
      ns: ns,
      box: {
        top: top,
        left: left,
        width: sizing === 'border-box' ? width : width - pR - pL,
        height: sizing === 'border-box' ? height : height - pT - pB
      },
      style: style.cssText
    });
  };
  return handler;
}
export { createClickHandler };