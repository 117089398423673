export const StatusConstants = {
    SELECT_STATUS: 0,
    NO_STATUS: 1,
    ACTIVATED: 2,
    ISSUED: 3,
    DELETED: 4,
    DEACTIVATED: 5,
    TEST_READY: 6,
    RETIRED: 7,
    ACTIVATION_READY: 8,
    INVENTORY: 9,
    REPLACED: 10,
    ACTIVATION_PENDING: 11,
    SUSPENDED: 12,
    WAITING_FOR_ACTIVATION: 13,
    WAITING_FOR_DEACTIVATION: 14,
    TEST_USAGE_EXHAUSTED: 15,
    DATA_USAGE_EXHAUSTED: 16,
    SMS_LIMIT_EXCEEDED: 18
};

export const ProviderIds = {
    CONNECT_PLUS: 1,
    TECH_PLUS_PRO: 2,
    TECH_PLUS_BASIC: 3,
    DATA_PLUS: 4,
    LINK_PLUS: 5
};


export const STATUS_ICON_MAPPING: { [index: number]: string } = {
    [StatusConstants.SELECT_STATUS]: 'button-status-select',
    [StatusConstants.NO_STATUS]: '',
    [StatusConstants.ACTIVATED]: 'button-activated',
    [StatusConstants.ISSUED]: 'button-issued',
    [StatusConstants.DELETED]: 'button-deleted',
    [StatusConstants.DEACTIVATED]: 'button-deactivated',
    [StatusConstants.TEST_READY]: 'button-test-ready',
    [StatusConstants.RETIRED]: 'button-retired',
    [StatusConstants.ACTIVATION_READY]: 'button-activation-ready',
    [StatusConstants.INVENTORY]: 'button-inventory',
    [StatusConstants.REPLACED]: 'button-replaced',
    [StatusConstants.ACTIVATION_PENDING]: 'button-activation-pending',
    [StatusConstants.SUSPENDED]: 'button-suspended',
    [StatusConstants.WAITING_FOR_ACTIVATION]: 'status-pending', // waiting for activation
    [StatusConstants.WAITING_FOR_DEACTIVATION]: 'status-pending', // waiting for deactivation
    [StatusConstants.TEST_USAGE_EXHAUSTED]: 'button-free-usage-exhausted',
    [StatusConstants.DATA_USAGE_EXHAUSTED]: 'button-monthly-usage-exhausted',
    [StatusConstants.SMS_LIMIT_EXCEEDED]: 'button-sms-limit-exceeded'
};


export const USAGE_TYPE_DATA = 1;
export const USAGE_TYPE_VOICE = 2;
export const USAGE_TYPE_SMS = 3;

// can be removed once WP-750 is done
export const IS_VOICE_USAGE_DISPLAYED = false;
