import { debounce } from './utils.js';
import { validAttributes } from './vars.js';
var mutationTriggeringElements = {};
function ignoreMutation(ele) {
  if (ele.uniqueID) {
    var info = mutationTriggeringElements[ele.uniqueID];
    if (info && info.triggered > 10 && info.lastTriggerDate + 500 < Date.now()) {
      if (!info.warned && console) {
        console.warn('locize ::: ignoring element change - an element is rerendering too often in short interval', '\n', 'consider adding the "data-locize-editor-ignore:" attribute to the element:', ele);
        info.warned = true;
      }
      return true;
    }
  }
  var ret = ele.dataset && (ele.dataset.i18nextEditorElement === 'true' || ele.dataset.locizeEditorIgnore === 'true');
  if (!ret && ele.parentElement) return ignoreMutation(ele.parentElement);
  return ret;
}
function createObserver(ele, handle) {
  var internalChange;
  var lastToggleTimeout;
  var toggleInternal = function toggleInternal() {
    if (lastToggleTimeout) clearTimeout(lastToggleTimeout);
    lastToggleTimeout = setTimeout(function () {
      if (internalChange) internalChange = false;
    }, 200);
  };
  var targetEles = [];
  var debouncedHandler = debounce(function h() {
    handle(targetEles);
    targetEles = [];
  }, 100);
  var observer = new MutationObserver(function (mutations) {
    if (internalChange) {
      toggleInternal();
      return;
    }
    var triggerMutation = false;
    mutations.forEach(function (mutation) {
      if (mutation.type === 'attributes' && !validAttributes.includes(mutation.attributeName)) {
        return;
      }
      Object.keys(mutationTriggeringElements).forEach(function (k) {
        var info = mutationTriggeringElements[k];
        if (info.lastTriggerDate + 60000 < Date.now()) {
          delete mutationTriggeringElements[k];
        }
      });
      if (mutation.type === 'childList') {
        var notOurs = 0;
        if (!ignoreMutation(mutation.target)) {
          mutation.addedNodes.forEach(function (n) {
            if (ignoreMutation(n)) return;
            notOurs = notOurs + 1;
          }, 0);
          mutation.removedNodes.forEach(function (n) {
            if (ignoreMutation(n)) return;
            notOurs = notOurs + 1;
          }, 0);
        }
        if (notOurs === 0) return;
      }
      triggerMutation = true;
      if (mutation.target && mutation.target.uniqueID) {
        var info = mutationTriggeringElements[mutation.target.uniqueID] || {
          triggered: 0
        };
        info.triggered = info.triggered + 1;
        info.lastTriggerDate = Date.now();
        mutationTriggeringElements[mutation.target.uniqueID] = info;
      }
      var includedAlready = targetEles.reduce(function (mem, element) {
        if (mem || element.contains(mutation.target) || !mutation.target.parentElement) {
          return true;
        }
        return false;
      }, false);
      if (!includedAlready) {
        targetEles = targetEles.filter(function (element) {
          return !mutation.target.contains(element);
        });
        targetEles.push(mutation.target);
      }
    });
    if (triggerMutation) debouncedHandler();
  });
  return {
    start: function start() {
      var observerConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true
      };
      observer.observe(ele, observerConfig);
    },
    skipNext: function skipNext() {
      internalChange = true;
    }
  };
}
export { createObserver };