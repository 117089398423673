<ng-template #content let-modal>
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="modal-header" data-cy="modal-create-user-dialog">
      <h1 class="modal-title fs-5" id="createUserLabel">{{ 'user-management.createUser.label' | i18next }}</h1>
      <button type="reset" class="btn-close" aria-label="Close" [disabled]="inProgress"
              (click)="onClose()">
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label class="form-label" for="create-user-email-input">
          {{ 'email' | i18nextCap }}
        </label>
        <input class="form-control" data-cy="create-user-email-input" id="create-user-email-input" type="text"
               formControlName="email">
        <div *ngIf="this.formGroup.get('email')?.touched && this.formGroup.get('email')?.errors !== null"
             class="text-danger fs-6 mt-1 d-flex flex-row "
             data-cy="create-user-email-error">
          <span class="fa-solid fa-circle-exclamation me-1 justify-content-center p-1"></span>
          <span>
            {{ 'settings.notifications.email-error' | i18next }}
          </span>
        </div>
      </div>
      <div class="mt-3 form-group">
        <label class="form-label" for="create-user-role-input">
          {{ 'user-management.createUser.role' | i18next }}
          <i id="create-user-role-info-icon" class="fa-regular fa-circle-question"></i>
          <app-default-popover
            [text]="i18next.transform('roles.popover.description')"
            componentId="create-user-role-info-icon">
          </app-default-popover>
        </label>
        <app-dropdown-button formControlName="role" [options]="roleOptions" id="create-user-role-input"
                             data-cy="create-user-role-dropdown"/>
      </div>
      <div class="mt-3 form-group">
        <label class="form-label" for="create-user-lang-input">{{ 'language' | i18next }}</label>
        <app-dropdown-button formControlName="language" [options]="languageOptions"
                             data-cy="create-user-language-dropdown" id="create-user-lang-input"/>
      </div>
      <div class="alert alert-custom mt-3 d-flex align-items-center gap-2" role="alert">
        <i class="fa-solid fa-circle-info"></i>
        <span>{{ 'user-management.createUser.noticeText' | i18next }}</span>
      </div>
    </div>
    <div class="modal-footer">
      <div class="d-flex float-end gap-3">
        <button type="reset" [disabled]="inProgress" (click)="onClose()"
                class="btn btn-outline-secondary" data-cy="cancel-user-creation-btn">
          {{ 'shared.btn.cancel' | i18next }}
        </button>
        <button type="submit" [disabled]="formGroup.invalid || inProgress"
                class="btn btn-secondary" data-cy="create-user-submit-btn">
          <i class="fa-solid fa-check ok-icon me-1"></i>{{ 'user-management.createUser.add' | i18next }}
          <span *ngIf="inProgress" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
