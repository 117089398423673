import { Component, Input } from '@angular/core';
import { HorizontalAlignment, PositionAlignment, VerticalAlignment } from 'devextreme/common';

@Component({
  selector: 'app-default-popover',
  templateUrl: './default-popover.component.html',
  styleUrl: './default-popover.component.scss'
})
export class DefaultPopoverComponent {

    @Input({required: true})
    public text: string | undefined;

    @Input({required: true})
    public componentId: string | undefined;

    @Input()
    public position: PositionAlignment | { x?: HorizontalAlignment, y?: VerticalAlignment } | undefined;

}
