(function () {
  if (typeof Document === 'undefined') return;
  var nextID = 1;
  if (Document.prototype.hasOwnProperty('uniqueID')) {
    return;
  }
  console.info('"document.uniqueID" not implemented; creating shim');
  Object.defineProperty(Document.prototype, 'uniqueID', {
    get: function get() {
      return nextID++;
    },
    enumerable: false,
    configurable: false
  });
  Object.defineProperty(Element.prototype, 'uniqueID', {
    get: function get() {
      Object.defineProperty(this, 'uniqueID', {
        value: document.uniqueID,
        writable: false,
        enumerable: false,
        configurable: false
      });
      return this.uniqueID;
    },
    enumerable: false,
    configurable: true
  });
})();