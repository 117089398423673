import { Component, HostBinding, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ApplyColumnStateParams,
    CellClickedEvent,
    ColDef,
    ColGroupDef,
    ColumnMovedEvent,
    ColumnResizedEvent,
    FilterChangedEvent,
    FilterModel,
    GetLocaleTextParams,
    GetRowIdParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IRowNode,
    SelectionChangedEvent
} from 'ag-grid-community';
import { I18NextPipe } from 'angular-i18next';
import { Subscription } from 'rxjs';
import { Sim } from '../../../graphql/graphql.generated';
import { environment } from '../../environments/environment';
import { MsisdnFormatPipe } from '../pipes/msisdn-format.pipe';
import { Auth, Permission } from '../service/auth/WSimAuth';
import { GraphqlService } from '../service/graphql.service';
import { LoggerService } from '../service/logger';
import { ToastService } from '../service/toast.service';
import { UserConfigService } from '../service/user-config.service';
import { IS_VOICE_USAGE_DISPLAYED } from '../shared/constants';
import {
    getActivationTimestampDefinition,
    getCheckBoxDefinition,
    getCustomfield1Definition,
    getIccidDefinition,
    getImeiDefinition,
    getImsiDefinition,
    getInSessionSinceDefinition,
    getMonthlyDataDefinition,
    getMonthlyDataLimitDefinition,
    getMonthlySmsDefinition,
    getMonthlySmsLimitDefinition,
    getMonthlyVoiceUsageDefinition,
    getMsisdnDefinition,
    getPreviousMonthDataVolumeDefinition,
    getProviderIdDefinition,
    getStatusIdDefinition
} from './col-definitions';
import { SimDatasource } from './sim-data-source';
import { SimDetailComponent } from './sim-detail/sim-detail.component';


@Component({
    selector: 'app-main',
    templateUrl: './sim-list.component.html',
    styleUrls: ['./sim-list.component.scss']
})
export class SimListComponent implements OnDestroy {

    @HostBinding('class')
    private readonly classes = 'container-fluid d-flex flex-column flex-fill';

    @ViewChild(SimDetailComponent)
    private simDetailComponent!: SimDetailComponent;
    private updatedSimSubscription?: Subscription;
    private permissions: Set<string>;

    // AG Grid definitions
    protected readonly datasourceForTable: SimDatasource;

    protected gridOptions;
    protected selectedRows?: Sim[] = undefined;
    protected gridApi?: GridApi;
    protected showBulkActions: boolean = false;

    public constructor(private graphQlClient: GraphqlService,
                       private i18nextPipe: I18NextPipe,
                       private msisdnFormatPipe: MsisdnFormatPipe,
                       private route: ActivatedRoute,
                       private router: Router,
                       private userConfig: UserConfigService,
                       private toastService: ToastService) {

        this.permissions = Auth.getcurrentUserPermissionsList();
        this.showBulkActions = this.showCheckBoxes();
        this.datasourceForTable = new SimDatasource(this.graphQlClient, this.toastService);
        this.gridOptions = this.getGridOptions();
    }

    protected getGridOptions(): GridOptions {
        return {
            onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
            onSelectionChanged: (event: SelectionChangedEvent) => this.onSelectionChanged(event),
            onColumnResized: (event: ColumnResizedEvent) => this.onColumnResized(event),
            onColumnVisible: () => this.onColumnVisibleChanged(),
            onColumnMoved: (event: ColumnMovedEvent) => this.onColumnMoved(event),
            getLocaleText: (params: GetLocaleTextParams) => {
                return this.i18nextPipe.transform(params.key, {
                    ns: 'grid',
                    defaultValue: params.defaultValue
                });
            },
            onFilterChanged: (event: FilterChangedEvent) => this.onFilterChanged(event),
            getRowId: (params: GetRowIdParams) => this.getRowId(params),
            rowClassRules: {
                'bg-primary-subtle': (params) => {
                    return (this.simDetailComponent.currentSim() && params.data && params.data.iccid === this.simDetailComponent.currentSim()?.iccid);
                }
            },
            datasource: this.datasourceForTable,
            columnDefs: this.createColumnDefinitions(),
            defaultColDef: {
                flex: 1,
                resizable: true,
                minWidth: 100,
                sortable: false
            },
            overlayNoRowsTemplate: '<div>No Rows</div>',
            overlayLoadingTemplate: '<div class="spinner-border m-5" role="status">\n' +
                '  <span class="visually-hidden">Loading...</span>\n' +
                '</div>',
            animateRows: false,
            cacheBlockSize: environment.aggrid.cacheBlockSize,
            cacheOverflowSize: environment.aggrid.cacheOverflowSize,
            infiniteInitialRowCount: environment.aggrid.infiniteInitialRowCount,
            rowBuffer: environment.aggrid.rowBuffer,
            rowModelType: 'infinite',
            rowSelection: 'multiple',
            maxConcurrentDatasourceRequests: 1,
            enableCellTextSelection: true,
            ensureDomOrder: true,
            cellFlashDuration: 2000,
            suppressRowTransform: true,
            rowMultiSelectWithClick: true,
            suppressRowClickSelection: true,
            stopEditingWhenCellsLoseFocus: true
        };
    }

    private showCheckBoxes(): boolean {
        return (this.permissions.has(Permission.SIM_WRITE_SMSLIMIT) ||
            this.permissions.has(Permission.SIM_WRITE_DATALIMIT) ||
            this.permissions.has(Permission.SIM_WRITE_CUSTOMFIELD1) ||
            this.permissions.has(Permission.SIM_WRITE_STATUS));
    }

    private createColumnDefinitions(): (ColDef | ColGroupDef)[] {
        let colDef: ColDef[] = [
            getIccidDefinition((event: CellClickedEvent): void => this.openDetails(event)),
            getCustomfield1Definition(this.permissions, (event: CellClickedEvent): void => this.openDetails(event)),
            getStatusIdDefinition(),
            getInSessionSinceDefinition(),
            getPreviousMonthDataVolumeDefinition(),
            getMonthlyDataDefinition(),
            getMonthlyDataLimitDefinition(this.permissions),
            IS_VOICE_USAGE_DISPLAYED ? getMonthlyVoiceUsageDefinition() : undefined,
            getMonthlySmsDefinition(this.permissions),
            getMonthlySmsLimitDefinition(this.permissions),
            getProviderIdDefinition(this.i18nextPipe),
            getImeiDefinition(),
            getImsiDefinition(),
            getMsisdnDefinition(this.msisdnFormatPipe),
            getActivationTimestampDefinition(this.i18nextPipe)
        ].filter((column): column is ColDef => column !== undefined);


        if (this.showCheckBoxes()) {
            colDef = [getCheckBoxDefinition(), ...colDef];
        }

        colDef.filter((col) => col.field).forEach((col) => {
            col.headerName = this.i18nextPipe.transform('table.header.' + col.field);
        });
        return colDef;
    }

    private onFilterChanged(event: FilterChangedEvent): void {

        const filterModel: FilterModel = event.api.getFilterModel();
        let filter = null;
        if (filterModel && !(Object.keys(filterModel).length === 0 && filterModel.constructor === Object)) {
            filter = JSON.stringify(filterModel);
        }

        this.router.navigate([], {
            queryParams: { filter },
            queryParamsHandling: 'merge'
        }).then();
    }

    private onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.loadTableConfig(this.gridApi);
        this.datasourceForTable.setGridApi(this.gridApi);
        params.api.setGridOption('loading', true);
        params.api.showNoRowsOverlay();
        this.createSubscriptions();

        // Set filter from URL parameter
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['filter']) {
            this.gridApi.setFilterModel(JSON.parse(queryParams['filter']));
        }
    }

    private onSelectionChanged(params: SelectionChangedEvent) {
        LoggerService.debug('onSelectionChanged(): ', params);
        const selectedRows = params.api.getSelectedRows();
        if (selectedRows && selectedRows.length > 1) {
            LoggerService.debug('onSelectionChanged(): multiselect');
            this.selectedRows = selectedRows;
        } else if (selectedRows && selectedRows.length == 1) {
            LoggerService.debug('onSelectionChanged(): single select');
            this.selectedRows = selectedRows;
        } else {
            LoggerService.debug('onSelectionChanged(): no row selected');
            this.selectedRows = undefined;
        }
        this.showBulkActions = (this.showCheckBoxes() && !!this.selectedRows);
    }

    private onColumnResized(params: ColumnResizedEvent) {
        if (params.finished) {
            this.storeLocalConfig(this.gridApi!);
        }
    }

    private onColumnMoved(params: ColumnMovedEvent) {
        if (params.finished) {
            this.storeLocalConfig(this.gridApi!);
        }
    }

    private onColumnVisibleChanged() {
        this.storeLocalConfig(this.gridApi!);
    }

    public ngOnDestroy(): void {
        if (this.updatedSimSubscription) {
            this.updatedSimSubscription.unsubscribe();
        }
    }

    private createSubscriptions() {
        if (this.updatedSimSubscription) {
            this.updatedSimSubscription.unsubscribe();
        }
        this.updatedSimSubscription = this.graphQlClient.subscribeUpdatedSim().subscribe((data: Sim) => {
            const rowNode = this.gridApi?.getRowNode(data.iccid);
            if (rowNode) {
                rowNode.updateData(data);
            }
            if (this.selectedRows?.find((element) => element.iccid === data.iccid)) {
                this.selectedRows = this.gridApi?.getSelectedRows();
            }
        });
    }

    private getRowId(param: GetRowIdParams) {
        return param.data.iccid;
    }

    private storeLocalConfig(gridApi: GridApi) {
        const columnState = gridApi.getColumnState();
        this.userConfig.getUserConfig()?.then((configMap) => {
            configMap.set(UserConfigService.KEY_COLUMN_STATE_SIM_LIST, JSON.stringify(columnState));
        });
    }

    private loadTableConfig(gridApi: GridApi) {
        this.userConfig.getUserConfig()?.then((configMap) => {
            const columnModel = configMap.get(UserConfigService.KEY_COLUMN_STATE_SIM_LIST);
            if (columnModel) {
                const stateParams: ApplyColumnStateParams = {
                    applyOrder: true,
                    defaultState: { sort: null },
                    state: JSON.parse(columnModel)
                };
                gridApi.applyColumnState(stateParams);
            }
        });
    }

    protected quickSearchEvent($event: string) {
        this.datasourceForTable.setQuickFilter($event);
        this.gridApi?.setGridOption('datasource', this.datasourceForTable);
    }

    private openDetails(event: CellClickedEvent) {
        let open = true;
        // check if the event's cell is in edit mode. If so, do not
        // open the detail component! This fixes the problem with editing
        // column and opening the detail view for device name!
        event.api?.getEditingCells().forEach((cellPosition) => {
            if (cellPosition.column.getColId() === event.column.getColId() && cellPosition.rowIndex === event.rowIndex) {
                open = false;
                return;
            }
        });
        if (open) {
            this.simDetailComponent.open(event.data);
        }

    }

    protected onDetailsSimChanged($event: { old: Sim | undefined, new: Sim | undefined }) {
        const redrawNodes: IRowNode<Sim>[] = [];
        [$event.old, $event.new].forEach((e: Sim | undefined) => {
            if (!e) return;
            const rowNode = this.gridApi?.getRowNode(e.iccid);
            if (rowNode) redrawNodes.push(rowNode);
        });
        this.gridApi?.redrawRows({ rowNodes: redrawNodes });
    }
}
