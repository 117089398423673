import _defineProperty from '@babel/runtime/helpers/esm/defineProperty';
import { resetHighlight } from './ui/highlightNode.js';
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
var data = {};
function clean() {
  Object.values(data).forEach(function (item) {
    if (!document.body.contains(item.node)) {
      resetHighlight(item.id, item.node);
      delete data[item.id];
    }
  });
}
function save(id, subliminal, type, meta, node, children) {
  if (!id || !type || !meta || !node) return;
  if (!data[id]) {
    data[id] = {
      id: id,
      node: node,
      subliminal: subliminal
    };
  }
  data[id].keys = _objectSpread(_objectSpread({}, data[id].keys), {}, _defineProperty({}, "".concat(type), meta));
  if (children) {
    data[id].children = _objectSpread(_objectSpread({}, data[id].children), {}, _defineProperty({}, "".concat(type, "-").concat(children.map(function (c) {
      return c.childIndex;
    }).join(',')), children));
  }
}
function get(id) {
  return data[id];
}
var store = {
  save: save,
  clean: clean,
  get: get,
  data: data
};
export { store };