import { Component, EventEmitter, inject, Output, TemplateRef, ViewChild } from '@angular/core';
import { ApolloError, FetchResult } from '@apollo/client/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { UserAccount } from '../../../../graphql/graphql.generated';
import { GraphqlService } from '../../service/graphql.service';
import { LoggerService } from '../../service/logger';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';

@Component({
    selector: 'app-modal-resend-user-invite',
    templateUrl: './modal-resend-user-invite.component.html',
    styleUrl: './modal-resend-user-invite.component.scss'
})
export class ModalResendUserInviteComponent {

    @ViewChild('content')
    private content: TemplateRef<never> | undefined;
    private modal?: NgbModalRef;

    protected selectedUserAccount?: UserAccount;

    protected inProgress: boolean = false;

    private graphql = inject(GraphqlService);
    private toastService = inject(ToastService);
    private i18next = inject(I18NextPipe);
    private modalService = inject(NgbModal);


    @Output() private resentInvite: EventEmitter<void> = new EventEmitter();

    public open() {
        this.modal = this.modalService.open(this.content, { ariaLabelledBy: 'resendUserInviteLabel' });
        return this.modal;
    }

    protected onClose() {
        this.modal?.close();
    }

    protected resend() {
        if (this.selectedUserAccount?.id) {
            this.inProgress = true;
            this.graphql.resendUserAccountInvite(this.selectedUserAccount.id).then((value: FetchResult<boolean>): void => {
                this.promiseResolver(value);
                this.modal?.close();
            }).catch((error: ApolloError): void => {
                this.graphqlErrorResolver(error);
            }).finally(() => {
                this.inProgress = false;
            });
        }
    }

    /**
     * Successful resolver on response from backend
     * @Param value This is the fetched value from the promise being handled
     * */
    private promiseResolver(value: FetchResult<boolean>): void {
        if (value.data) {
            LoggerService.info('Invite was sent', value.data);
            this.resentInvite.emit();
        } else {
            const errorText = value.errors?.[0].message;
            const text: string = this.i18next.transform('user-management.resendUserAccountInvite.graphql-operations.error',
                { error: errorText });
            this.toastService.show(ToastMessageBuilder.error().text(text).build());
        }
    }

    /**
     * For handling error coming from the graphql errors
     * @Param error The error catch from the promise
     * */
    private graphqlErrorResolver(error: ApolloError): void {
        const text: string = this.i18next.transform('user-management.resendUserAccountInvite.graphql-operations.error',
            { error: error.message });
        this.toastService.show(ToastMessageBuilder.error().text(text).build());
    }

    public setSelectedUserAccount(userAccount: UserAccount) {
        this.selectedUserAccount = userAccount;
    }
}
