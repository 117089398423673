import _defineProperty from '@babel/runtime/helpers/esm/defineProperty';
import { colors } from '../vars.js';
import { RibbonBox } from './elements/ribbonBox.js';
import { computePosition, flip, shift, offset, arrow } from '@floating-ui/dom';
var eleToOutline = ['DIV', 'P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'OL', 'UL', 'ADDRESS', 'BLOCKQUOTE', 'DL', 'PRE'];
var overriddenStyles = ['outline', 'border-radius', 'outline-offset', 'filter'];
var originalStyles = {};
var selected = {};
function highlight(item, node, keys) {
  var id = item.id;
  if (selected[id]) return;
  if (!originalStyles[id]) {
    originalStyles[id] = overriddenStyles.reduce(function (mem, s) {
      mem[s] = node.style[s];
      return mem;
    }, {});
  }
  if (eleToOutline.includes(node.nodeName)) {
    node.style.outline = "".concat(colors.highlight, " solid 1px");
    node.style.setProperty('border-radius', '1px');
    node.style.setProperty('outline-offset', '2px');
    node.style.filter = 'brightness(110%)';
  } else {
    node.style.outline = "".concat(colors.highlight, " solid 1px");
    node.style.setProperty('border-radius', '1px');
    node.style.setProperty('outline-offset', '1px');
    node.style.filter = 'brightness(110%)';
  }
  if (!item.ribbonBox) {
    var _RibbonBox = RibbonBox(keys),
      actions = _RibbonBox.box,
      arrowEle = _RibbonBox.arrow;
    document.body.appendChild(actions);
    var refEle = node;
    if (node.childNodes.length === 1) {
      var childNode = node.childNodes[0];
      if (childNode && childNode.nodeName === '#text') {
        var range = document.createRange();
        range.selectNode(childNode);
        var rect = range.getBoundingClientRect();
        refEle = {
          getBoundingClientRect: function getBoundingClientRect() {
            return rect;
          }
        };
      }
    }
    computePosition(refEle, actions, {
      placement: 'right',
      middleware: [flip({
        fallbackPlacements: ['left', 'bottom']
      }), shift(), offset(function (_ref) {
        var placement = _ref.placement,
          rects = _ref.rects;
        if (placement === 'bottom') return rects.r;
        return 35;
      }), arrow({
        element: arrowEle
      })]
    }).then(function (_ref2) {
      var x = _ref2.x,
        y = _ref2.y,
        middlewareData = _ref2.middlewareData,
        placement = _ref2.placement;
      Object.assign(actions.style, {
        left: "".concat(x, "px"),
        top: "".concat(y, "px"),
        display: 'inline-flex'
      });
      var side = placement.split('-')[0];
      var staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right'
      }[side];
      if (middlewareData.arrow) {
        var _middlewareData$arrow = middlewareData.arrow,
          _x = _middlewareData$arrow.x,
          _y = _middlewareData$arrow.y;
        Object.assign(arrowEle.style, _defineProperty(_defineProperty({
          left: _x != null ? "".concat(_x, "px") : '',
          top: _y != null ? "".concat(_y, "px") : '',
          right: '',
          bottom: ''
        }, staticSide, "".concat(side === 'bottom' ? -18 : -25, "px")), "transform", side === 'bottom' ? 'rotate(90deg)' : side === 'left' ? 'rotate(180deg)' : ''));
      }
    });
    item.ribbonBox = actions;
  }
}
function highlightUninstrumented(item, node, keys) {
  var id = item.id;
  if (selected[id]) return;
  if (!originalStyles[id]) {
    originalStyles[id] = overriddenStyles.reduce(function (mem, s) {
      mem[s] = node.style[s];
      return mem;
    }, {});
  }
  if (eleToOutline.includes(node.nodeName)) {
    node.style.outline = "".concat(colors.warning, " solid 1px");
    node.style.setProperty('border-radius', '1px');
    node.style.setProperty('outline-offset', '2px');
    node.style.filter = 'brightness(110%)';
  } else {
    node.style.outline = "".concat(colors.warning, " solid 1px");
    node.style.setProperty('border-radius', '1px');
    node.style.setProperty('outline-offset', '1px');
    node.style.filter = 'brightness(110%)';
  }
}
function selectedHighlight(item, node, keys) {
  var id = item.id;
  if (!originalStyles[id]) {
    originalStyles[id] = overriddenStyles.reduce(function (mem, s) {
      mem[s] = node.style[s];
      return mem;
    }, {});
  }
  if (eleToOutline.includes(node.nodeName)) {
    node.style.outline = "".concat(colors.highlight, " solid 1px");
    node.style.setProperty('border-radius', '1px');
    node.style.setProperty('outline-offset', '2px');
    node.style.filter = "brightness(110%) drop-shadow(0px 0px 2px ".concat(colors.highlight, " )");
  } else {
    node.style.outline = "".concat(colors.highlight, " solid 1px");
    node.style.setProperty('border-radius', '1px');
    node.style.setProperty('outline-offset', '1px');
    node.style.filter = "brightness(110%) drop-shadow(0px 0px 2px ".concat(colors.highlight, " )");
  }
  if (item.ribbonBox) {
    document.body.removeChild(item.ribbonBox);
    delete item.ribbonBox;
  }
  selected[id] = true;
}
function resetHighlight(item, node, keys) {
  var ignoreSelected = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var id = item.id;
  if (ignoreSelected && selected[id]) return;
  if (originalStyles[id]) {
    overriddenStyles.forEach(function (s) {
      node.style.setProperty(s, originalStyles[id][s]);
    });
    delete originalStyles[id];
  }
  if (item.ribbonBox) {
    document.body.removeChild(item.ribbonBox);
    delete item.ribbonBox;
  }
  delete selected[id];
}
export { highlight, highlightUninstrumented, resetHighlight, selectedHighlight };