var INVISIBLE_CHARACTERS = ["\u200B", "\u200C"];
var INVISIBLE_REGEX = RegExp("([".concat(INVISIBLE_CHARACTERS.join(''), "]{9})+"), 'gu');
var TEMPLATE_MINIMUM_LENGTH = '{"k":"a"}'.length;
var invisibleStartMarker = 'subliminal:start';
var toBytes = function toBytes(text) {
  return Array.from(new TextEncoder().encode(text));
};
var fromBytes = function fromBytes(bytes) {
  return new TextDecoder().decode(new Uint8Array(bytes));
};
var padToWholeBytes = function padToWholeBytes(binary) {
  var needsToAdd = 8 - binary.length;
  return '0'.repeat(needsToAdd) + binary;
};
var encodeMessage = function encodeMessage(text) {
  var bytes = toBytes(text).map(Number);
  var binary = bytes.map(function (byte) {
    return padToWholeBytes(byte.toString(2)) + '0';
  }).join('');
  var result = Array.from(binary).map(function (b) {
    return INVISIBLE_CHARACTERS[Number(b)];
  }).join('');
  return result;
};
var encodedInvisibleStartMarker = encodeMessage(invisibleStartMarker);
var decodeMessage = function decodeMessage(message) {
  var binary = Array.from(message).map(function (character) {
    return INVISIBLE_CHARACTERS.indexOf(character);
  }).map(String).join('');
  var textBytes = binary.match(/(.{9})/g);
  var codes = Uint8Array.from((textBytes === null || textBytes === void 0 ? void 0 : textBytes.map(function (byte) {
    return parseInt(byte.slice(0, 8), 2);
  })) || []);
  return fromBytes(codes);
};
var decodeFromText = function decodeFromText(text) {
  var _text$match;
  var invisibleMessages = (_text$match = text.match(INVISIBLE_REGEX)) === null || _text$match === void 0 ? void 0 : _text$match.filter(function (m) {
    return m.length > TEMPLATE_MINIMUM_LENGTH - 1;
  });
  if (!invisibleMessages || invisibleMessages.length === 0) return;
  return decodeMessage(invisibleMessages[invisibleMessages.length - 1]);
};
var removeInvisibles = function removeInvisibles(text) {
  return text.replace(INVISIBLE_REGEX, '');
};
var encodeValue = function encodeValue(data) {
  if (Object.keys(data).length === 0) return data;
  var value = {
    k: data.key,
    n: data.ns,
    l: data.lng,
    s: data.source
  };
  return JSON.stringify(value);
};
var decodeValue = function decodeValue(value) {
  if (!value || typeof value !== 'string' || value.indexOf('{') !== 0) return;
  try {
    var parsed = JSON.parse(value || '{}');
    return {
      key: parsed.k,
      ns: parsed.n,
      lng: parsed.l,
      source: parsed.s
    };
  } catch (e) {
    return undefined;
  }
};
function wrap(text) {
  var invisibleMeta = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var encodedValue = encodeValue(invisibleMeta);
  var invisibleMark = encodeMessage(encodedValue);
  return typeof text === 'string' && text ? encodedInvisibleStartMarker + text + invisibleMark : text;
}
function unwrap(text) {
  var encodedValue = decodeFromText(text);
  var decodedVal = decodeValue(encodedValue);
  var result = removeInvisibles(text);
  return {
    text: result,
    invisibleMeta: decodedVal
  };
}
function containsHiddenMeta(text) {
  if (!text || text.length < 27) return false;
  if (!INVISIBLE_REGEX.test(text)) return false;
  var lastByte = text.substring(text.length - 9);
  var lastChar = decodeMessage(lastByte);
  return lastChar === '}';
}
function containsHiddenStartMarker(text) {
  return text.startsWith(encodedInvisibleStartMarker);
}
export { containsHiddenMeta, containsHiddenStartMarker, unwrap, wrap };