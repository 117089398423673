<ng-template #content let-modal>
  <div class="modal-header" data-cy="modal-delete-user-dialog">
    <h1 class="modal-title fs-5" id="delete-user-label">{{ 'user-management.deleteUser.label' | i18next }}</h1>
    <button type="reset" class="btn-close" aria-label="Close" [disabled]="inProgress"
            (click)="onClose()">
    </button>
  </div>

  <div class="modal-body">
    <span>
      {{ 'user-management.deleteUser.question' | i18next : {user: this.selectedUserAccount?.email} }}
    </span>
  </div>

  <div class="modal-footer">
    <div class="d-flex float-end gap-3">
      <button type="reset" [disabled]="inProgress" (click)="onClose()"
              class="btn btn-outline-secondary" data-cy="cancel-user-deletion-btn">
        {{ 'shared.btn.cancel' | i18next }}
      </button>
      <button type="submit" [disabled]="inProgress" (click)="onDelete()"
              class="btn btn-danger" data-cy="delete-user-submit-btn">
        <i class="fa-solid fa-trash-can"></i>
        {{ 'user-management.deleteUser.delete' | i18next }}
        <span *ngIf="inProgress" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</ng-template>
