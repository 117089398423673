{
  "WSIM-Super-Admin": [
    "sim.write.customfield1",
    "sim.write.status",
    "sim.write.datalimit",
    "sim.write.smslimit",
    "sim.execute.sendsms",
    "sim.execute.cancellocation",
    "customer.write.usagenotifications",
    "customer.read.usagenotifications",
    "customer.write.accounts",
    "customer.read.accounts"
  ],
  "Administrator": [
    "sim.write.customfield1",
    "sim.write.status",
    "sim.write.datalimit",
    "sim.write.smslimit",
    "sim.execute.sendsms",
    "sim.execute.cancellocation",
    "customer.write.usagenotifications",
    "customer.read.usagenotifications",
    "customer.write.accounts",
    "customer.read.accounts"
  ],
  "Observer": [
  ],
  "Manager": [
    "sim.write.customfield1",
    "sim.write.status",
    "sim.write.datalimit",
    "sim.write.smslimit",
    "sim.execute.sendsms",
    "sim.execute.cancellocation",
    "customer.read.usagenotifications",
    "customer.read.accounts"
  ]
}