import { Component, EventEmitter, inject, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { environment } from '../../../environments/environment';
import { Auth } from '../../service/auth/WSimAuth';
import { GraphqlService } from '../../service/graphql.service';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';
import { LoggerService } from '../../service/logger';
import { ApolloError, FetchResult } from '@apollo/client/core';
import { CreateUserAccountMutation } from '../../../../graphql/graphql.generated';
import i18next from 'i18next';

@Component({
    selector: 'app-modal-create-user',
    templateUrl: './modal-create-user.component.html',
    styleUrl: './modal-create-user.component.scss'
})

export class ModalCreateUserComponent {
    public inProgress = false;

    // this is the same email regex we use the backend
    private EMAIL_REGEX = new RegExp('\\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Z|a-z]{2,7}\\b');
    protected roles: string[] =  new Array<string>(...Auth.getPermissionsKeys());
    protected readonly supportedLanguages = environment.supportedLanguages;
    private readonly zoneInfo?: string;
    private readonly customerId?: number;

    private graphql = inject(GraphqlService);
    private toastService = inject(ToastService);
    protected i18next = inject(I18NextPipe);
    private modalService = inject(NgbModal);

    @ViewChild('content')
    private content: TemplateRef<never> | undefined;
    private modal?: NgbModalRef;
    public formGroup = new FormGroup({
        email: new FormControl('', { nonNullable: true, validators: [Validators.pattern(this.EMAIL_REGEX), Validators.required] }),
        role: new FormControl('Manager', { nonNullable: true, validators: [Validators.required] }),
        language: new FormControl(this.i18next.transform(i18next.language), { nonNullable: true, validators: [Validators.required] })
    });
    protected languageOptions;
    protected roleOptions;
    @Output() private userCreated: EventEmitter<void> = new EventEmitter();

    public constructor() {
        this.zoneInfo = Auth.getUserTimeZone();
        this.customerId = Auth.getCurrentCustomerId();
        this.languageOptions = environment.supportedLanguages.map((language) => ({
            label: this.i18next.transform('lang.' + language), value: language
        }));
        if (!Auth.isAdmin()) this.roles = this.roles.filter(item => item !== 'WSIM-Super-Admin');
        this.roleOptions = this.roles.map((role) => ({
            label: this.i18next.transform('user-management.createUser.role-' + role), value: role
        }));
    }

    public open() {
        this.modal = this.modalService.open(this.content, { ariaLabelledBy: 'createUserLabel' });
        return this.modal;
    }

    protected onClose() {
        this.formGroup.reset();
        this.modal?.close();
    }

    protected onSubmit(): void {
        this.inProgress = true;
        const { email, role, language } = this.formGroup.value;
        if (!this.zoneInfo || !this.customerId) {
            const errorText = 'Internal user information missing';
            this.toastService.show(ToastMessageBuilder.error().text(
                this.i18next.transform('user-management.createUser.graphql-operations.error',
                    { error: errorText })).build());
            return;
        }
        this.graphql.createUserAccount(email!, language!, this.zoneInfo, this.customerId, [role!]
        ).then((value: FetchResult<CreateUserAccountMutation>): void => {
            this.promiseResolver(value);
            this.modal?.close();
            this.formGroup.reset();
            this.userCreated.emit();
        }).catch((error: ApolloError): void => {
            this.graphqlErrorResolver(error);
        }).finally(() => {
            this.inProgress = false;
        });
    }

    /**
     * Display a message according the current operation,
     * then set the current usage notification to the fetched values or to the default values,
     * after that the current operation is set to invalid, and at the end the values for isStoring and isLoading are set to false
     * @Param value This is the fetched value from the promise being handled
     * */
    private promiseResolver(value: FetchResult<CreateUserAccountMutation>): void {
        if (value.data != undefined || null) {
            LoggerService.info('User was created', value.data);
        } else {
            const errorText = value.errors?.[0].message;
            const text: string = this.i18next.transform('user-management.createUser.graphql-operations.error', { error: errorText });
            this.toastService.show(ToastMessageBuilder.error().text(text).build());
        }
    }

    /**
     * For handling error coming from the graphql promises
     * @Param error The error catch from the promise
     * */
    private graphqlErrorResolver(error: ApolloError): void {
        const text: string = this.i18next.transform('user-management.createUser.graphql-operations.error', { error: error.message });
        this.toastService.show(ToastMessageBuilder.error().text(text).build());
    }
}
