import {Component, EventEmitter, inject, Output, TemplateRef, ViewChild} from '@angular/core';
import {ApolloError, FetchResult} from '@apollo/client/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {I18NextPipe} from 'angular-i18next';
import {DeleteUserAccountMutation, UserAccount} from '../../../../graphql/graphql.generated';
import {GraphqlService} from '../../service/graphql.service';
import {LoggerService} from '../../service/logger';
import {ToastMessageBuilder, ToastService} from '../../service/toast.service';


@Component({
    selector: 'app-modal-delete-user',
    templateUrl: './modal-delete-user.component.html',
    styleUrl: './modal-delete-user.component.scss'
})
export class ModalDeleteUserComponent {
    @ViewChild('content')
    private content: TemplateRef<never> | undefined;
    private modal?: NgbModalRef;

    protected selectedUserAccount?: UserAccount;

    protected inProgress = false;

    private graphql = inject(GraphqlService);
    private toastService = inject(ToastService);
    protected i18next = inject(I18NextPipe);
    private modalService = inject(NgbModal);

    @Output() private userDeleted: EventEmitter<void> = new EventEmitter();

    public open() {
        this.modal = this.modalService.open(this.content, {ariaLabelledBy: 'delete-user-label'});
        return this.modal;
    }

    protected onClose() {
        this.modal?.close();
    }

    protected onDelete(): void {
        if (this.selectedUserAccount?.id) {
            this.inProgress = true;
            this.graphql.deleteUserAccount(this.selectedUserAccount?.id).then((value: FetchResult<DeleteUserAccountMutation>): void => {
                this.promiseResolver(value);
                this.modal?.close();
                this.userDeleted.emit();
            }).catch((error: ApolloError): void => {
                this.graphqlErrorResolver(error);
            }).finally(() => {
                this.inProgress = false;
            });
        }
    }

    /**
     * Display a message according the current operation,
     * then set the current usage notification to the fetched values or to the default values,
     * after that the current operation is set to invalid, and at the end the values for isStoring and isLoading are set to false
     * @Param value This is the fetched value from the promise being handled
     * */
    private promiseResolver(value: FetchResult<DeleteUserAccountMutation>): void {
        if (value.data) {
            LoggerService.info('User was deleted', value.data);
        } else {
            const errorText = value.errors?.[0].message;
            const text: string = this.i18next.transform('user-management.deleteUser.graphql-operations.error', {error: errorText});
            this.toastService.show(ToastMessageBuilder.error().text(text).build());
        }
    }

    /**
     * For handling error coming from the graphql promises
     * @Param error The error catch from the promise
     * */
    private graphqlErrorResolver(error: ApolloError): void {
        const text: string = this.i18next.transform('user-management.deleteUser.graphql-operations.error', {error: error.message});
        this.toastService.show(ToastMessageBuilder.error().text(text).build());
    }

    public setUserAccount(userAccount: UserAccount) {
        this.selectedUserAccount = userAccount
    }
}
