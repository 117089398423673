import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


type Value = number | string
type Option = { value: Value, label: string }

@Component({
    selector: 'app-dropdown-button',
    templateUrl: './dropdown-button.component.html',
    styleUrl: './dropdown-button.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownButtonComponent),
            multi: true,
        },
    ],
})

export class DropdownButtonComponent implements ControlValueAccessor {
    @Input({ required: true })
    public options: Option[] = [];
    public selectedOption?: Option;

    private _onChange?: (value: Value) => void;
    private _onTouch?: ((touched: boolean) => void);
    private _isDisabled: boolean;

    public get isDisabled(): boolean {
        return this._isDisabled;
    }

    private constructor() {
        this._isDisabled = false
    }

    public optionClicked(option: Option) {
        this.selectedOption = option;
        this._onChange?.(this.selectedOption.value);
        this._onTouch?.(true);
    }

    // the form control passes the default value on reset
    public writeValue(obj: Option | Value): void {
        if (typeof obj === 'object') {
            this.selectedOption = obj;
        } else {
            this.selectedOption = this.options.find((option) => option.value == obj);
        }
    }

    public registerOnChange(fn: () => void): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this._onTouch = fn;
    }
    public setDisabledState?(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
    }
}
