<ng-template #content let-modal >
  <form [formGroup]="formGroup" data-cy="modal-update-user">
    <div class="modal-header ">
        <div class="modal-title text-secondary fs-2" id="updateUserLabel">
          {{ 'user-management.popup.dialog.title.change-group'|i18nextCap }}
        </div>
        <button type="reset" class=" btn btn-close pe-2" aria-label="Close"
             (click)="onClose()">
        </button>
    </div>

    <div class="modal-body">
      <div>
        {{ 'user-management.popup.dialog.text.change-group' | i18next:  {email: selectedData?.email} }}
      </div>
      <div class="fw-bold pb-3 pt-3" >
          {{ 'user-management.createUser.role' | i18next }}
          <i id="update-user-role-info-icon" class="fa-regular fa-circle-question"></i>
          <app-default-popover
            [text]="i18nextPipe.transform('roles.popover.description')"
            componentId="update-user-role-info-icon">
          </app-default-popover>
      </div>
        <app-dropdown-button formControlName="role" [options]="roles"
                             data-cy="update-user-role-dropdown"/>
    </div>
    <div class="modal-footer">
      <div class="d-flex float-end gap-3">
        <div type="reset"  (click)="onClose()"
                class="btn btn-outline-secondary" data-cy="cancel-user-update-btn">
          {{ 'shared.btn.cancel' | i18next }}
        </div>
        <button [disabled]="formGroup.untouched || formGroup.value.role === currentRole" type="submit" (click)="onSubmit() "
                class="btn btn-secondary" data-cy="update-user-submit-btn">
          <i class="fa-solid fa-check"></i>
          {{ 'shared.btn.change' | i18next }}
        </button>
      </div>
    </div>
  </form>
</ng-template>

