<div class="container-fluid">
  <div class="row">
    <div class="col-lg-3"></div>
    <div class="col-lg-6 ">
      <div class="row justify-content-center">
        <form class="col-12 d-flex flex-column justify-content-start">
          <div class="row fs-5 fw-bold">{{ 'settings.notifications.title' | i18next }}</div>
          <div class="row pt-2 pb-3 fst-normal">
            {{ 'settings.notifications.text' | i18next:  {company: companyName} }}
          </div>
          <div class="row">
            <div class="form-check col-12">
              <input class="form-check-input" data-cy="usage-percent90-checkbox" id="btn-90-percent" type="checkbox"
                     (change)="onInput()" [(ngModel)]="currentUsageNotification.percent90"
                     [checked]="currentUsageNotification.percent90"
                     [disabled]="isLoading"
                     name="90%" ngModel>
              <label class="form-check-label" for="btn-90-percent">
                {{ 'settings.notifications.90%' | i18next }}
              </label>
            </div>
            <div class="form-check col-12">
              <input class="form-check-input" data-cy="usage-percent100-checkbox" id="btn-100-percent" type="checkbox"
                     (change)="onInput()" [(ngModel)]="currentUsageNotification.percent100"
                     [checked]="currentUsageNotification.percent100"
                     [disabled]="isLoading"
                     name="100%" ngModel>
              <label class="form-check-label" for="btn-100-percent">
                {{ 'settings.notifications.100%' | i18next }}
              </label>
            </div>
            <div class="row pb-4">
              <div class="row fs-6 fw-bold pt-3 pb-2">{{ 'settings.notifications.email-label' | i18next }}
              </div>
              <div class="row pb-1">{{ 'settings.notifications.email-text' | i18next }}</div>
              <div class="p-0">
                <span *ngIf="isLoading" class="placeholder col-11"></span>
                <div
                  *ngFor="let email of currentUsageNotification.emails; let last=last; let i = index; trackBy:trackByIdentityOrIndex"
                  class="py-2">
                  <div class="d-flex flex-row">
                    <div class="col-11" data-cy="text-container">
                      <input (input)="onInput()" [(ngModel)]="currentUsageNotification.emails[i]"
                             [class.is-invalid]="!isThisEmailValid(email) || isEmailDuplicated(email,i)"
                             [class.is-valid]="isThisEmailValid(email) && !isEmailDuplicated(email,i)"
                             class="form-control" data-cy="usage-email-input"
                             name="email:{{i}}" ngModel
                             type="email">
                    </div>
                    <div class="col-1 ms-2 align-content-center">
                      <button (click)="removeEmail(i)" *ngIf="currentUsageNotification.emails.length > 1"
                              class="btn btn-link" data-cy="remove-email" name="remove:{{i}}">
                        <i class="fa-solid fa-trash"></i>
                      </button>

                    </div>
                  </div>
                  <div *ngIf="!isThisEmailValid(email)" class="text-danger fs-6 mt-1 d-flex flex-row "
                       data-cy="usage-email-error">
                    <span class="fa-solid fa-circle-exclamation me-1 justify-content-center p-1"></span>
                    <span>
                  {{ 'settings.notifications.email-error' | i18next }}
                </span>
                  </div>
                  <div *ngIf="isEmailDuplicated(email, i)" class="text-danger fs-6 mt-1 d-flex flex-row "
                       data-cy="usage-email-error">
                <span
                  class="fa-solid fa-circle-exclamation me-1 justify-content-center p-1">
                </span>
                    <span>
                  {{ 'settings.notifications.email-duplicate' | i18next }}
                </span>
                  </div>
                  <div *ngIf="last" class="mt-2">
                    <button (click)="addEmail()" data-cy="add-email" class="btn btn-primary">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-start p-0">
              <button (click)="onSubmit()"
                      [disabled]="(currentOperation === 'INVALID') || isLoading || isStoring"
                      class="btn btn-primary" data-cy="usage-submit-btn" type="submit">
                {{ 'button.save' | i18next }}
                <span *ngIf="isStoring" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-lg-3"></div>
  </div>
</div>
