<div class="dropdown">
  <button class="form-control d-flex align-items-center justify-content-between"
          data-bs-toggle="dropdown"
          aria-expanded="true">
    <span>{{ selectedOption?.label }}</span>
    <i class="fa-solid fa-chevron-down"></i>
  </button>
  <ul class="dropdown-menu form-control">
    <ng-container *ngTemplateOutlet="optionList"></ng-container>
  </ul>
</div>

<ng-template #optionList>
  <li *ngFor="let option of options">
    <button (click)="optionClicked(option)" [class]="(selectedOption?.value === option.value)?'active':''"
            class="dropdown-item"
            data-toggle="collapse" data-target=".navbar-collapse.show"
            type="button">
      {{ option.label }}
    </button>
  </li>
</ng-template>
