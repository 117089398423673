<ng-template #content let-modal>
  <div class="modal-header" data-cy="modal-resend-user-invite">
    <h1 class="modal-title fs-5" id="resendUserInviteLabel">{{ 'user-management.resendUserAccountInvite.label' | i18next }}</h1>
    <button type="reset" class="btn-close" aria-label="Close" [disabled]="inProgress" (click)="onClose()">
    </button>
  </div>

  <div class="modal-body">
    {{ 'user-management.resendUserAccountInvite.statement' | i18nextCap: { email: selectedUserAccount?.email } }}
    <div class="alert alert-info mt-3 d-flex align-items-center gap-2" role="alert">
      <i class="fa-solid fa-circle-info"></i>
      <span>{{ 'user-management.resendUserAccountInvite.noticeText' | i18nextCap }}</span>
    </div>
  </div>

  <div class="modal-footer">
    <div class="d-flex float-end gap-3">
      <button type="reset" [disabled]="inProgress" (click)="onClose()" class="btn btn-outline-secondary"
              data-cy="resend-user-invite-cancel-btn">
        {{ 'shared.btn.cancel' | i18next }}
      </button>
      <button type="submit" [disabled]="inProgress"
              class="btn btn-secondary" data-cy="resend-user-invite-submit-btn" (click)="resend()">
        <i class="fa-solid fa-envelope ok-icon me-1"></i>
        {{ 'user-management.resendUserAccountInvite.send' | i18nextCap }}
        <span *ngIf="inProgress" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</ng-template>

